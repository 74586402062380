self.addEventListener('message', function(e) {

    var http = new XMLHttpRequest();
    var url = e.data;
    http.open("POST", url, true);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    http.onreadystatechange = function() {//Call a function when the state changes.
        if(http.readyState == 4 && http.status == 200) {
            self.postMessage(JSON.parse(http.responseText));
            
        }
    }
    http.send();

}, false);

